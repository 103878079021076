.Home {
  width: 100%;
  min-height: calc(100vh - 60px);
  /* background: url("../../images/stars.svg"), #1b1b25; */
  background-repeat: repeat;

  color: #ffffff;

  display: flex;
  align-items: center;
}

.Home__col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .Home__col {
    align-items: flex-start;
  }
}
