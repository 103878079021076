.SidebarWrapper {
  background: #4b6a78;
  position: absolute;
  /*right: 0;*/
  width: 240px;
  height: 100%;
  min-height: 800px;
  color: #fff;
}

.SidebarWrapper a {
  color: #fff;
}

.SidebarWrapper .ProfileContainer {
  padding: 30px;
  background: rgba(0, 0, 0, 0.2);
  text-align: center;
  color: #fff;
}

.SidebarWrapper .ProfileName {
  font-size: 20px;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 10px;
}

.SidebarWrapper .Tagline {
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}

.SidebarWrapper .ProfilePhoto {
  margin-bottom: 15px;
}

.SidebarWrapper .ContactList li {
  margin-bottom: 10px;
}

.SidebarWrapper .ContactList li,
i {
  margin-right: 10px;
}
.SidebarWrapper .ContactList li,
a {
}

.SidebarWrapper .ContainerBlock {
  padding: 15px 30px;
}

.SidebarWrapper .ContainerBlockTitle {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 15px;
}

.SidebarWrapper .Degree {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 5px;
}

.SidebarWrapper .EducationContainer .Item {
  margin-bottom: 15px;
}

.SidebarWrapper .EducationContainer .Meta {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 500;
  margin-bottom: 0px;
  margin-top: 0;
  font-size: 14px;
}

.SidebarWrapper .EducationContainer .Time {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 500;
  margin-bottom: 0px;
}

.SidebarWrapper .LanguagesContainer .LangDesc {
  color: rgba(255, 255, 255, 0.6);
}

.SidebarWrapper .LanguagesList {
  margin-bottom: 0;
}

.SidebarWrapper .LanguagesList li {
  margin-bottom: 10px;
}

.SidebarWrapper .InterestsList {
  margin-bottom: 5px;
}

.SidebarWrapper .InterestsList li {
  margin-bottom: 10px;
}

@media (max-width: 767.98px) {
  .SidebarWrapper {
    position: static;
    width: inherit;
  }
}
